import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        seasons: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('seasons', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        markets: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('markets', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        sales: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('sales', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        growers: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('growers', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        generateVoucher: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('reports/village_sales', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    }
}
